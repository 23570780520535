import { graphql } from 'gatsby';
//styles
import { colors, device } from '../components/GlobalStyles';
import styled from '@emotion/styled';
//layout
import { DefaultLayout } from '../layouts/DefaultLayout';
//images
import camera from '../images/technology/intraoral-camera.png';
import xray from '../images/technology/digital-xray.png';
import laser from '../images/technology/diagnodent-laser.png';
//components
import { Seo } from '../components/Seo';
import { Technology } from '../components/Technology';
import { DoctorsSlide } from '../components/DoctorsSlide';
import { YellowButton } from '../components/layout/StyledComponents';

const TestimonialsStyled = styled.section`
    padding-top: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        margin-left: 0.5em;
    }
    h3 {
        padding-left: 0.5em;
        margin: 1em 0em;
    }
    button {
        cursor: pointer;
        margin-left: 1em;
        border: 1px solid ${colors.grey};
        padding: 1em;
        margin-bottom: 2em;
        font-size: 18px;
        &:hover {
            background: ${colors.grey};
            color: white;
        }
    }
    iframe {
        width: 380px;
        height: 215px;
        margin: 0 auto;
        margin-bottom: 2em;
    }
    p {
        margin: 1em 0em;
    }
    @media ${device.laptop} {
        padding-top: 8em;
        display: flex;
        flex-direction: row;
        padding-bottom: 4em;
        justify-content: space-around;
        h1 {
            margin-left: 0.2em;
        }
        p {
            position: absolute;
            top: 500px;
        }
        iframe {
            width: 560px;
            height: 315px;
            margin-top: 2em;
        }
    }
`;

export const Testimonials: React.FC = () => {
    return (
        <main>
            <Seo
                title="Patient testimonials – SmileTampa"
                description="Patient testimonials and reviews from past case studies"
            />
            <TestimonialsStyled>
                <div>
                    <h1>Patient testimonials</h1>
                    <h3>WE LOVE TO HEAR FROM YOU!</h3>
                    <a
                        target="__blank"
                        href="https://www.google.com/search?q=smile+tampa++Prosthodontics+and+Implant+Therapy+revierw+google&ei=lGFxYZjeIIzmU7z6qegE&ved=0ahUKEwiY-NL5xNvzAhUM8xQKHTx9Ck0Q4dUDCA4&uact=5&oq=smile+tampa++Prosthodontics+and+Implant+Therapy+revierw+google&gs_lcp=Cgdnd3Mtd2l6EAMyBAghEAo6CwgAELADEA0QBRAeOgsIABCwAxAIEA0QHjoECAAQQzoFCAAQkQI6CwguEMcBEK8BEJECOhEILhCABBCxAxCDARDHARCjAjoLCAAQgAQQsQMQgwE6DgguEIAEELEDEMcBEKMCOg4ILhCABBCxAxDHARDRAzoICAAQgAQQsQM6EQguEIAEELEDEIMBEMcBENEDOgsILhCABBDHARCvAToNCC4QxwEQ0QMQQxCTAjoHCAAQsQMQQzoFCAAQgAQ6DQguELEDEMcBENEDEEM6BQguEIAEOgsILhCABBDHARDRAzoOCC4QgAQQxwEQrwEQkwI6BggAEBYQHjoFCCEQoAE6BwghEAoQoAFKBAhBGAFQ7aYBWKm9AWCYvgFoB3AAeAGAAZQBiAGgFpIBBTEyLjE1mAEAoAEBoAECyAECwAEB&sclient=gws-wiz#lrd=0x88c2c1388e3c5575:0xb3051970e1eb3aad,1,,,"
                    >
                        <button>Leave Us A Review</button>
                    </a>
                </div>
                <div>
                    <iframe
                        src="https://www.youtube.com/embed/2j7M2ddkuqw"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                {/* <p>Read what our patients are saying:</p> */}
            </TestimonialsStyled>
        </main>
    );
};

export default Testimonials;

Testimonials.Layout = DefaultLayout;
